import React from "react";
import styles from './Styles.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export default function PreviewAlert({className}) {
  return (
      <div className={cx(styles.container, className)}>
        <a href="/api/exit-preview">
          Click here
        </a>{' '}
        to exit preview mode.
      </div>
  )
}
