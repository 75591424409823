const campaigns = `
*[
  _type == 'campaign'
] {
  'sites': sites[] -> .slug.current,
  startDate,
  endDate,
  'mobile': mobile[]{
    'url':image.asset->.url,
    'alt':altTag,
    'href':link,
  },
  'tablet': tablet[]{
    'url':image.asset->.url,
    'alt':altTag,
    'href':link,
  },
  'desktop': desktop[]{
    'url':image.asset->.url,
    'alt':altTag,
    'href':link,
  },
  'banner': banner[]{
    'url':image.asset->.url,
    'alt':altTag,
    'href':link,
  },
  homepageTakeover[]{
    'left': left{
      'url':image.asset->.url,
      'alt':altTag,
      'href':link,
    },
    'right': right{
      'url':image.asset->.url,
      'alt':altTag,
      'href':link,
    },
    'center': center{
      'url':image.asset->.url,
      'alt':altTag,
      'href':link,
    },
  }
} [
  $site in sites
  && $date <= endDate
]
`
module.exports = campaigns
