import React from "react";
import styles from './Styles.module.scss';
import Link from "next/link";
import Logo from "@/components/svg/Logo";
import {useContext} from "@/lib/utils/context";
import {categoryToPath} from "@/lib/utils/categoryUtils";
import IconFb from "@/components/svg/IconFb";
import IconInsta from "@/components/svg/IconInsta";
import getConfig from "next/config";
import classNames from 'classnames/bind';
import AccountIcon from "@/components/svg/AccountIcon";

const cx = classNames.bind(styles);

export default function Footer() {

  const {site, categories, user} = useContext();

  const categoryToLink = (category) => ({
    title: category.title,
    link: categoryToPath(category),
  });

  const menuItems = [
    ...categories.map(categoryToLink),
  ];

  const {socialLinks} = getConfig().publicRuntimeConfig;

  return (
      <footer className={styles.container}>
        <div className={styles.top}>
          <div className={styles.logoContainer}>
            <Logo className={styles.logo}/>

          </div>
          <div className={styles.links}>
            <div className={styles.topFooter}>

              {menuItems.map((item, i) => (
                  <div
                      key={i}
                      className={styles.footerLink}>
                    <a href={item.link}>{item.title}</a>
                  </div>
              ))}

            </div>
            <div className={styles.bottomFooter}>
              <div>
                {user && <Link href={'/account'}>
                  <a className={styles.account}>
                    Account
                    <AccountIcon className={styles.icon}/>
                  </a>
                </Link>}
              </div>
              <div>
                <Link href={"/about-us"}>
                  <a>About {site.name}</a>
                </Link>
              </div>
              <div>
                <Link href={"/contact-us"}>
                  <a>Contact Us</a>
                </Link>
              </div>
              <div>
                <Link href={"/privacy"}>
                  <a>Privacy Policy</a>
                </Link>
              </div>
              <div>
                <Link href={"/terms"}>
                  <a>Terms & Conditions</a>
                </Link>
              </div>
              <div>
                <Link href={"https://schoolroad.nz"}>
                  <a>School Road Publishing</a>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.bottom}>

          <div className={styles.socialButtons}>
            <a href={socialLinks.facebook} target="_blank">
              <IconFb/>
            </a>
            <a href={socialLinks.instagram} target="_blank">
              <IconInsta/>
            </a>
          </div>

          <div className={styles.copyright}>
          <span>
            © {new Date().getFullYear()} <span className={styles.siteName}>{site.name}</span> · All Rights Reserved
            </span>
          </div>

        </div>

      </footer>
  )
}
