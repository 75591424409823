import React from "react";

export default function CircleTick({className}) {
  return (
      <svg className={className} viewBox="0 0 32 32">
        <path
            d="M13.6666 22.3333C13.3484 22.3332 13.0432 22.2067 12.8182 21.9817L8.01823 17.1817C7.79323 16.9565 7.66688 16.6512 7.66699 16.3329C7.6671 16.0145 7.79367 15.7093 8.01883 15.4843C8.244 15.2593 8.54933 15.1329 8.86765 15.133C9.18598 15.1332 9.49122 15.2597 9.71622 15.4849L13.6666 19.4353L22.417 10.6849C22.6421 10.4598 22.9475 10.3333 23.2659 10.3333C23.5844 10.3333 23.8898 10.4598 24.1149 10.6849C24.3401 10.9101 24.4666 11.2155 24.4666 11.5339C24.4666 11.8523 24.3401 12.1577 24.1149 12.3829L14.515 21.9829C14.2898 22.2075 13.9847 22.3335 13.6666 22.3333Z"
            fill="currentColor"/>
        <path
            d="M16 32C12.8355 32 9.74207 31.0616 7.11088 29.3035C4.4797 27.5454 2.42894 25.0466 1.21793 22.1229C0.00693257 19.1993 -0.309921 15.9823 0.307443 12.8786C0.924806 9.77486 2.44866 6.92394 4.6863 4.6863C6.92394 2.44866 9.77486 0.924806 12.8786 0.307443C15.9823 -0.309921 19.1993 0.00693257 22.1229 1.21793C25.0466 2.42894 27.5454 4.4797 29.3035 7.11088C31.0616 9.74207 32 12.8355 32 16C31.9954 20.2421 30.3082 24.3091 27.3086 27.3086C24.3091 30.3082 20.2421 31.9954 16 32ZM16 2.66667C13.3629 2.66667 10.7851 3.44866 8.5924 4.91374C6.39975 6.37883 4.69078 8.46121 3.68161 10.8976C2.67244 13.3339 2.4084 16.0148 2.92287 18.6012C3.43734 21.1876 4.70722 23.5634 6.57192 25.4281C8.43661 27.2928 10.8124 28.5627 13.3988 29.0771C15.9852 29.5916 18.6661 29.3276 21.1024 28.3184C23.5388 27.3092 25.6212 25.6003 27.0863 23.4076C28.5513 21.215 29.3333 18.6371 29.3333 16C29.3295 12.465 27.9234 9.07585 25.4238 6.5762C22.9242 4.07656 19.535 2.67056 16 2.66667Z"
            fill="currentColor"/>
      </svg>
  )
}
