import React from "react";

export default function CtaArrow({className}) {
  return (<>
    <div className={'arrow ' + className}>
      <div className={'head top'}/>
      <div className={'head bottom'}/>
      <div className={'tail'}/>
    </div>

    <style jsx>{`
      .arrow {
        position: relative;
        overflow: hidden;
      }

      .head {
        position: absolute;
        right: 0;
        width: 1px;
        height: 50%;
        background-color: currentColor;
        border-radius: 99px;
      }

      .head.top {
        bottom: 50%;
        transform: rotate(-45deg);
        transform-origin: 0 100%;
      }

      .head.bottom {
        top: 50%;
        transform: translateY(-1px) rotate(45deg);
        transform-origin: 0 0;
      }

      .tail {
        position: absolute;
        width: 100%;
        right: 1px;
        top: 50%;
        transform: translateY(-100%);
        height: 1px;
        background-color: currentColor;
        border-radius: 99px;
      }
    `}</style>
  </>);
}
