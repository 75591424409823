import React from "react";
import {useContext} from "@/lib/utils/context";
import styles from './Styles.module.scss';
import Link from "next/link";
import GiftBox from "@/components/svg/GiftBox";
import CloseButton from "@/components/button/close/CloseButton";
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export default function Banner({className}) {

  const {
    site,
    banners,
    setBanners,
  } = useContext();

  function onClose() {
    setBanners(prevState => ({...prevState, mothersDay: {closed: true}}))
  }

  if (banners?.mothersDay?.closed) {
    return null;
  }

  return (
      <div className={cx(styles.banner, className)}>
        <Link href={"/gift"}>
          <a className={styles.link}>
            <GiftBox className={styles.icon}/>
            Surprise someone special today with a premium {site.name} subscription
            <GiftBox className={styles.icon}/>
          </a>
        </Link>
        <CloseButton className={styles.closeButton} onClick={onClose}/>
      </div>
  );
}
