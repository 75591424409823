import {cloneDeep} from "lodash";
import {urlFor} from "@/lib/api/sanity/api";

export default function getImageUrls(obj, {width, height}) {
  if (!obj) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(node => getImageUrls(node, {width, height}))
  }
  if (typeof obj != 'object') {
    return obj;
  }
  const result = cloneDeep(obj);

  if (result._type === 'image' || result._type === 'inlineIcon') {
    let imageUrlBuilder = urlFor(result).width(width);
    if(!(result.url || "").includes(".gif")) {
      /**
       * Use auto format to let Sanity best decide which image format the
       * browser can handle.
       * Safari doesn't seem to render webp format well so can't explicitly
       * use that format.
       */
       imageUrlBuilder = imageUrlBuilder.auto("format");
    }
    if (height) {
      imageUrlBuilder = imageUrlBuilder.height(height);
    } else if (result.orientation === 'square') {
      imageUrlBuilder = imageUrlBuilder.height(width);
    } else if (result.orientation === 'landscape') {
      imageUrlBuilder = imageUrlBuilder.height(width * 1 / 2);
    } else if (result.orientation === 'portrait') {
      imageUrlBuilder = imageUrlBuilder.height(width * 6 / 5);
    }
    result.url = imageUrlBuilder.url();
  }

  for (let key in result) {
    result[key] = getImageUrls(result[key], {width, height});
  }
  return result;
}
