import React, {useState} from "react";
import styles from './Styles.module.scss';
import classNames from 'classnames/bind';
import Button from "@/components/button/Button";
import CloseButton from "@/components/button/close/CloseButton";

const cx = classNames.bind(styles);

export default function ConfirmDialog({message, yesCta, noCta, onConfirm, onClose}) {

  const [submitting, setSubmitting] = useState(false);

  async function handleConfirmClick() {
    try {
      setSubmitting(true);
      const confirmResult = onConfirm && onConfirm();
      if (confirmResult?.then) {
        await confirmResult;
      }
    } finally {
      setSubmitting(false);
    }
    handleCloseClick();
  }

  function handleCloseClick() {
    if (onClose && !submitting) {
      onClose();
    }
  }

  return (
      <div className={styles.background}>
        <div className={styles.backgroundClickSurface} onClick={handleCloseClick}/>
        <div className={styles.dialog}>
          <CloseButton className={styles.closeButton} onClick={handleCloseClick}/>
          <div className={styles.content}>
            <div className={styles.message}>{message}</div>
            <div className={styles.buttons}>
              <Button darkBackground
                      submitting={submitting}
                      className={cx(styles.button)}
                      onClick={handleConfirmClick}>
                {yesCta}
              </Button>
              <Button solid
                      darkBackground
                      disabled={submitting}
                      className={cx(styles.button)}
                      onClick={handleCloseClick}>
                {noCta}
              </Button>
            </div>
          </div>
        </div>
      </div>
  )
}
