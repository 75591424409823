import categoriesBySite from "@/lib/api/sanity/queries/categoriesBySite";
import storiesBySite from "@/lib/api/sanity/queries/storiesBySite";
import recentStoriesBySite from "@/lib/api/sanity/queries/recentStoriesBySite";
import recentStoriesByCategory from "@/lib/api/sanity/queries/recentStoriesByCategory";
import storyBySlug from "@/lib/api/sanity/queries/storyBySlug";
import previewStoryBySlug from "@/lib/api/sanity/queries/previewStoryBySlug";
import siteQuery from "@/lib/api/sanity/queries/site";
import categoryBySlug from "@/lib/api/sanity/queries/categoryBySlug";
import getConfig from "next/config";
import imageUrlBuilder from '@sanity/image-url'
import campaigns from "@/lib/api/sanity/queries/campaigns";
import {sub} from 'date-fns';
import filterOutDraftDuplicates from "@/lib/utils/filterOutDraftDuplicates";
import aboutUsBySite from "@/lib/api/sanity/queries/aboutUsBySite";
import termsBySite from "@/lib/api/sanity/queries/termsBySite";
import form from "@/lib/api/sanity/queries/form";
import previewForm from "@/lib/api/sanity/queries/previewForm";
import issuesBySite from "@/lib/api/sanity/queries/issuesBySite";
import privacyBySite from "@/lib/api/sanity/queries/privacyBySite";
import categoryById from "@/lib/api/sanity/queries/categoryById";
import storiesByIds from "@/lib/api/sanity/queries/storiesByIds";

const {site, sanity} = getConfig().publicRuntimeConfig;
const {projectId, dataset} = sanity;

const sanityClient = require('@sanity/client')
const options = {
  projectId,
  dataset,
  useCdn: true
}
const client = sanityClient(options);

function getClient(config) {
  return config?.preview ? sanityClient({
    ...options,
    token: config.serverRuntimeConfig.sanity.previewToken,
    useCdn: false
  }) : client;
}

function getPreviewClient(token) {
  return sanityClient({
    ...options,
    token: token,
    useCdn: false
  });
}

const builder = imageUrlBuilder(client);

export function urlFor(source) {
  return builder.image(source)
}

export function getIssuesBySite() {
  return client.fetch(issuesBySite, {site});
}

export function getCategoriesBySite() {
  return client.fetch(categoriesBySite, {site});
}

export function getStoriesBySite() {
  return client.fetch(storiesBySite, {site});
}

export async function getRecentStories({
                                         config,
                                         excludeIds = [],
                                         page = 0,
                                         limit = 12
                                       } = {}) {
  const args = {
    site,
    start: page * limit,
    end: page * limit + limit,
    excludeIds,
  };
  const result = await getClient(config).fetch(recentStoriesBySite, args);
  return filterOutDraftDuplicates(result);
}

export async function getRecentStoriesByCategory({
                                                   categoryId,
                                                   config,
                                                   excludeIds = [],
                                                   page = 0,
                                                   limit = 12
                                                 }) {
  const args = {
    site,
    categoryId,
    start: page * limit,
    end: page * limit + limit,
    excludeIds,
  };
  const result = await getClient(config).fetch(recentStoriesByCategory, args);
  return filterOutDraftDuplicates(result);
}

export function getStoryBySlug({slug, previewToken, config}) {
  const query = previewToken || config?.preview
      ? previewStoryBySlug
      : storyBySlug;
  const client = previewToken
      ? getPreviewClient(previewToken)
      : getClient(config);
  return client.fetch(query, {site, slug});
}

export function getStoriesByIds({ids}) {
  return getClient().fetch(storiesByIds, {ids, site:"A non existent site"});
}

export function getCategoryBySlug({slug, previewToken}) {
  return getPreviewClient(previewToken).fetch(categoryBySlug, {site, slug});
}

export function getSite(config) {
  return getClient(config).fetch(siteQuery, {site});
}

export function getCategoryById({categoryId, config}) {
  return getClient(config).fetch(categoryById, {site, categoryId});
}

export function getCampaigns() {
  const date = sub(new Date(), {days: 1});
  return client.fetch(campaigns, {site, date});
}

export function listenToStory({slug, config}) {
  const listener = getClient(config).listen(
      `*[_type == 'story' && slug.current == $slug]`,
      {slug});
  return {
    ...listener,
    subscribe: (callback) =>
        listener.subscribe(async (update) => {
          update.story = await getStoryBySlug({slug, config});
          callback(update);
        })
  }
}

export function getAboutUs(config) {
  return getClient(config).fetch(aboutUsBySite, {site});
}

export function getTerms(config) {
  return getClient(config).fetch(termsBySite, {site});
}

export function getPrivacy(config) {
  return getClient(config).fetch(privacyBySite, {site});
}

export function getFormById({id, config}) {
  const query = config?.preview
      ? previewForm
      : form;
  return getClient(config).fetch(query, {id});
}
