export function categoryToPath(category) {
  return categoryHierarchyArray(category).map(cat => cat.slug).join('/');
}

export function categoryHierarchyArray(category) {
  return category
      ? category.parent
          ? [...categoryHierarchyArray(category.parent), category]
          : [category]
      : [];
}

export function getChildCategories(category, allCategories) {
  return allCategories
      .filter(c => category !== c)
      .filter(c => categoryToPath(c).startsWith(categoryToPath(category)));
}
