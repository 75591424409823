import React from 'react';
import {useContext} from '@/lib/utils/context';
import SearchBar from './bar/SearchBar';
import styles from './SearchWidget.module.scss';

export default function SearchWidget() {

  const {setSearchFunction} = useContext();

  const backgroundClick = () => {
    setSearchFunction(false);
  };

  return (
    <div className={styles.background}>
      <div
        className={styles.backgroundClickSurface}
        onClick={backgroundClick}
      />
      <SearchBar />
    </div>
  );
}
