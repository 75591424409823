import React from "react";

export default function AccountIcon({ className }) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0 15.1196C0 11.7767 3.37765 9.06667 7.54417 9.06667C11.7107 9.06667 15.0884 11.7767 15.0884 15.1196C15.0884 16.3732 11.3163 17 7.54417 17C3.77209 17 0 16.3732 0 15.1196ZM7.54417 8.13829C8.34898 8.13829 9.1357 7.89964 9.80487 7.45252C10.474 7.0054 10.9956 6.36988 11.3036 5.62634C11.6116 4.8828 11.6921 4.06463 11.5351 3.2753C11.3781 2.48596 10.9906 1.76091 10.4215 1.19183C9.85242 0.622747 9.12736 0.235198 8.33803 0.0781893C7.54869 -0.0788196 6.73052 0.00176311 5.98698 0.309747C5.24344 0.617731 4.60793 1.13928 4.1608 1.80845C3.71368 2.47762 3.47503 3.26435 3.47503 4.06915C3.47503 4.60352 3.58028 5.13265 3.78477 5.62634C3.98927 6.12004 4.289 6.56862 4.66685 6.94647C5.04471 7.32433 5.49329 7.62406 5.98698 7.82855C6.48067 8.03304 7.00981 8.1383 7.54417 8.13829Z"
        fill="currentColor"
      />
    </svg>
  );
}
