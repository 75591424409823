import React from "react";
import styles from './MenuItem.module.scss';
import classNames from 'classnames/bind';
import Link from "next/link";
import {useRouter} from "next/router";

const cx = classNames.bind(styles);

export default function MenuItem(props) {
  const router = useRouter();
  const isSelected = router.pathname.startsWith(props.link);
  const title = props.title;
  const link = props.link;
  const href = props.href;
  const onClick = (e) => {
    if(props.onClick) {
      props.onClick();
    }
    if(props.func) {
      e.preventDefault();
      props.func();
    }
  };
  const children = props.children;
  return (
      <>
        <div className={cx(styles.container,{isSelected},props.className)}>
          {link ? (
                  <Link href={link.startsWith('/') ? props.link : `/${link}`}>
                    <a onClick={onClick}>{title}</a>
                  </Link>
              ) : (
                  <a href={href ?? ''} onClick={onClick}>{children}</a>
              )}
        </div>
        {/*<div className={styles.children}>*/}
        {/*  {children}*/}
        {/*</div>*/}
      </>
  )
}
