import React from "react";
import styles from './Styles.module.scss';
import classNames from 'classnames';
import {range} from "lodash/util";

export default function Loader({className, dotCount: dots = 8, period = 1.2}) {

  const renderDot = i => {
    const animationDelay = Math.round(100 * i * period / dots) / 100 + 's';
    const left = Math.round(Math.cos((2 * i * Math.PI) / dots) * 50 + 50) + '%';
    const top = Math.round(Math.sin((2 * i * Math.PI) / dots) * 50 + 50) + '%';
    const style = {
      left,
      top,
      animationDelay,
      animationDuration: `${period}s`,
      animationIterationCount: 'infinite',
    }
    return (
        <svg key={i} className={styles.dot} style={style} viewBox="0 0 2 2">
          <circle cx="1" cy="1" r="1" fill="currentColor"/>
        </svg>
    )
  }

  return (
      <div className={classNames(styles.container, className)}>
        <div className={styles.inner}>
          {range(dots).map(renderDot)}
        </div>
      </div>
  )
}
