import React, {useRef} from "react";
import styles from './Styles.module.scss';
import classNames from 'classnames';

export default function TextInput({
                                    className,
                                    type,
                                    placeholder,
                                    value,
                                    required,
                                    pattern,
                                    minLength,
                                    maxLength,
                                    disabled,
                                    name,
                                    defaultValue,
                                    register,
                                    onChange,
                                    onValueChange,
                                    onBlur,
                                    error,
                                    autoFocus
                                  }) {
  const ref = useRef();

  if (ref.current) {
    if (error) {
      ref.current.setCustomValidity(error);
    } else {
      ref.current.setCustomValidity('');
    }
  }

  const handleChange = (e) => {
    if (onChange) {
      onChange(e);
    }
    if (onValueChange) {
      onValueChange(e.target.value)
    }
  }

  return (
      <input
          value={value}
          disabled={disabled}
          name={name}
          ref={e => {
            ref.current = e;
            if (register) {
              register(e);
            }
          }}
          type={type}
          required={required}
          pattern={pattern}
          minLength={minLength}
          maxLength={maxLength}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={handleChange}
          onBlur={onBlur}
          autoFocus={autoFocus}
          className={classNames(styles.container, className)}
      />
  )
}
