import React from 'react';
import styles from './SearchButton.module.scss';
import SearchIcon from '@/components/svg/SearchIcon'
import classNames from 'classnames/bind'
import { useContext } from '@/lib/utils/context';
import CloseIcon from '@/components/svg/CloseIcon';

const cx = classNames.bind(styles);

export default function SearchButton({className, handleClick}) {

  const {searchFunction} = useContext();

  return (
    <div className={cx(styles.searchButton, className)} onClick={handleClick}>
      {!searchFunction && <SearchIcon />}
      {searchFunction && <CloseIcon />}
    </div>
  )
}
