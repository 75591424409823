const category = `
{
  'id': _id,
  title,
  slug,
  name,
  'site': site -> slug.current,
}
`

const bodyProjection = `
  body[]{
    ...,
    photographer->{name},
  },
`;

export function getStoryBase({body, similar} = {}) {
  return `
{
  ${body ? bodyProjection : ""}
  ${similar ? `similarStories[] -> ${getStoryBase()},` : ""}
  'id': _id,
  'slug': slug.current,
  sponsored,
  noAds,
  premium,
  premiumEndDate,
  headline,
  excerpt,
  publishedAt,
  thumbnail,
  mainContent[]{
    ...,
    photographer->{name},
  },
  author {
    'name': author -> name,
    'image': author -> image,
    'slug': author -> slug.current,
    hide
  },
  'categories': categories[] -> ${category} [site == $site],
  'allCategories': categories[] -> ${category},
}
`
}

export const storyBase = getStoryBase({body: true, similar: true});
export const storySummaryBase = getStoryBase();
