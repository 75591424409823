import {storySummaryBase} from "@/lib/api/sanity/queries/storyBase";

const site = `
* [
  _type == 'site'
  && slug.current == $site
] | order(!(_id in path("drafts.**"))) {
  'slug': slug.current,
  name,
  slogan,
  icon,
  backgroundImage,
  newsletterPopup,
  hero -> ${storySummaryBase},
  printHero -> ${storySummaryBase},
  video {
    file {
      asset-> {
        url
      }
    }
  },
  featuredStoryLists[] {
    title,
    stories[] -> ${storySummaryBase},
    categories[] ->,
  },
}[0]
`
export default site
