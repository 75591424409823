const categoriesBySite = `
* [
  _type == 'category'
] {
  'id': _id,
  title,
  slug,
  description,
  'site': site -> slug.current,
  parent -> {
    'id': _id,
    title,
    slug,
    name,
    'site': site -> slug.current,
    parent -> {
      'id': _id,
      title,
      slug,
      name,
      'site': site -> slug.current
    }
  }
} [
  site == $site
]
`
module.exports = categoriesBySite
