import React from "react";
import styles from './Menu.module.scss';
import classNames from 'classnames/bind';
import MenuItem from "@/components/nav/menu/item/MenuItem";
import {categoryToPath} from "@/lib/utils/categoryUtils";
import {useContext} from "@/lib/utils/context";
import Button from "@/components/button/Button";
import Link from "next/link";
import AccountIcon from "@/components/svg/AccountIcon";

const cx = classNames.bind(styles);

export default function Menu({onNavigate, className}) {

  const {config, categories, user} = useContext();

  const onMenuItemClick = () => {
    if (onNavigate) {
      onNavigate();
    }
  }

  const categoryToMenuItem = (category) => ({
    title: category.title,
    link: categoryToPath(category),
    children: (category.children ?? []).map(categoryToMenuItem),
  });

  const menuItemToComponent = (item, i) => (
      <MenuItem
          key={i}
          className={styles.item}
          title={item.title}
          link={item.link}
          onClick={onMenuItemClick}
      >
        {(item.children ?? []).map(menuItemToComponent)}
      </MenuItem>
  );

  const authItems = [];

  if (user) {
    authItems.push({
      title: <span className={styles.account}><AccountIcon className={styles.icon}/> Account</span>,
      link: "/account",
    });
  }

  const items = [
    ...categories.map(categoryToMenuItem),
    ...authItems,
  ];

  return (
      <div className={cx(styles.container, className)}>
        <div>
          {items.map(menuItemToComponent)}
        </div>
        <div className={styles.auth}>
          {config.allowMagazineSubscription &&
          <Link href={'/subscribe'}>
            <a>
              <Button solid
                      className={styles.button}
                      onClick={onMenuItemClick}>
                Subscribe
              </Button>
            </a>
          </Link>}
          {!user && <Link href={'/login'}>
            <a>
              <Button className={styles.button}
                      onClick={onMenuItemClick}>
                Log in
              </Button>
            </a>
          </Link>}
        </div>
      </div>
  )
}
