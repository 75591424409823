import React from "react";
import styles from './Styles.module.scss';
import classNames from 'classnames/bind';
import Loader from "@/components/loader/Loader";
import CircleTick from "@/components/svg/CircleTick";

const cx = classNames.bind(styles);

export default function Button({
                                 id,
                                 className,
                                 solid,
                                 black,
                                 darkBackground,
                                 children,
                                 onClick,
                                 disabled,
                                 submitting,
                                 done,
                                 innerRef
                               }) {

  function renderChildren() {
    if (done) {
      return <><CircleTick className={styles.tick}/>Done</>
    } else if (submitting) {
      return <Loader className={styles.loader}/>
    } else {
      return children;
    }
  }

  return (
      <button
          id={id}
          className={cx(
              styles.button,
              {solid, black, darkBackground, submitting, done, disabled},
              className)}
          disabled={disabled || submitting || done}
          onClick={onClick}
          ref={innerRef}>
        {renderChildren()}
      </button>
  )
}
