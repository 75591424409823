import React from "react";
import styles from './BurgerButton.module.scss';
import classNames from 'classnames/bind';
import {useRouter} from "next/router";

const cx = classNames.bind(styles);

export default function BurgerButton(props) {
  const router = useRouter();
  const isHome = router.pathname === "/";
  const isOpen = props.isOpen;
  const onClick = props.onClick;
  const invert = props.invert;
  return (
      <button onClick={onClick} className={cx(styles.container,{isOpen,invert,isHome},props.className)}>
        <div className={classNames(styles.line, styles.one)}/>
        <div className={classNames(styles.line, styles.two)}/>
        <div className={classNames(styles.line, styles.three)}/>
      </button>
  )
}
