import React from "react";

export default function IconFb({ className }) {
    return (
        <svg width="11" height="20" viewBox="0 0 11 20"
            fill="none" xmlns="http://www.w3.org/2000/svg"
            className={className}>
            <path d="M8.06322 3.80951H9.96045C10.2224 3.80951 10.4348 3.59629 10.4348 3.3333V0.476161C10.4347 0.213214 10.2224 0 9.96041 0H8.06317C5.18172 0 2.84583 2.34518 2.84583 5.23808V7.61902H0.47432C0.21237 7.61902 0 7.83223 0 8.09522V10.9524C0 11.2154 0.21237 11.4286 0.47432 11.4286H2.84583V20H6.64026V11.4286H9.01181C9.21587 11.4284 9.39698 11.2972 9.46145 11.1029L10.41 8.24571C10.4928 7.99621 10.3585 7.72656 10.1099 7.64344C10.0617 7.62732 10.0112 7.61906 9.96041 7.61902H6.64026V5.23808C6.6403 4.44911 7.27737 3.80951 8.06322 3.80951Z"
                fill="currentColor"
            />
        </svg>
    )
}
