import {storySummaryBase} from "@/lib/api/sanity/queries/storyBase";

const categoryBySlug = `
* [_type == 'category' && slug == $slug]
{
  'id': _id,
  title,
  slug,
  'site': site -> slug.current,
  description,
  hero -> ${storySummaryBase}
  trendingStories[] -> ${storySummaryBase}
}
[site == $site]
[0]
`
export default categoryBySlug;
