import React from "react";

export default function CloseIcon({className,onClick}) {
  return (
      <svg
          viewBox="0 0 25 21"
          fill="none"
          className={className}
          onClick={onClick}>
        <rect
            x="4.72192"
            y="0.601562"
            width="25"
            height="3"
            rx="1.5"
            transform="rotate(45 4.72192 0.601562)"
            fill="currentColor"/>
        <rect
            x="2.60034"
            y="18.2812"
            width="25"
            height="3"
            rx="1.5"
            transform="rotate(-45 2.60034 18.2812)"
            fill="currentColor"/>
      </svg>
  )
}
