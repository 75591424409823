import React from "react";
import styles from './Styles.module.scss';
import classNames from 'classnames/bind';
import CloseIcon from "@/components/svg/CloseIcon";
import {useContext} from "@/lib/utils/context";

const cx = classNames.bind(styles);

export default function CloseButton({className, disabled, onClick}) {
  const {submitting} = useContext().form;
  return <button
      disabled={disabled || submitting}
      className={cx(styles.closeButton, className)}
      onClick={onClick}
  >
    <CloseIcon/>
  </button>
}
