import React from "react";
import Head from 'next/head';
import {useContext} from "@/lib/utils/context";
import {urlFor} from "@/lib/api/sanity/api";

export default function Meta({data}) {
  const {site} = useContext();
  const logoUrl = (size) => urlFor(site.icon).width(size).height(size).url();
  return (
      <Head>
        <title>{data?.title || site.name}</title>
        <meta name="description" content={data?.description || site.slogan}/>

        // Facebook Meta Tags
        <meta property="og:type" content={data?.og?.type || "website"}/>
        <meta property="og:title" content={data?.og?.title || data?.title || site.name}/>
        <meta property="og:description" content={data?.og?.description || data?.description || site.slogan}/>
        <meta property="og:image" content={data?.og?.image || logoUrl(580)}/>

        // Twitter Meta Tags
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content={data?.twitter?.title || data?.title || site.name}/>
        <meta name="twitter:description" content={data?.twitter?.description || data?.description || site.slogan}/>
        <meta name="twitter:image" content={data?.twitter?.image || logoUrl(580)}/>

        <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap"
              rel="stylesheet"/>

        {[57, 60, 72, 76, 114, 120, 144, 152, 180]
            .map(size => <link key={size} rel="apple-touch-icon" sizes={`${size}x${size}`} href={logoUrl(size)}/>)}
        {[16, 32, 96, 192]
            .map(size => <link key={size} rel="icon" type="image/png" sizes={`${size}x${size}`} href={logoUrl(size)}/>)}

        {data?.canonical && <link rel="canonical" href={data.canonical}/>}
        <link rel="manifest" href="/manifest.json"/>
        <meta name="msapplication-TileColor" content="#ffffff"/>
        <meta name="msapplication-TileImage" content={logoUrl(144)}/>
        <meta name="theme-color" content="#ffffff"/>
      </Head>
  )
}
