import React from "react";
import styles from "./Styles.module.scss";
import classNames from 'classnames';

export default function CheckboxInput({
                                        name,
                                        required,
                                        children,
                                        className,
                                        checked,
                                        onChange,
                                        onValueChange,
                                        register
                                      }) {

  const handleChange = (e) => {
    if (onChange) {
      onChange(e);
    }
    if (onValueChange) {
      onValueChange(e.target.checked)
    }
  }

  return (
      <label className={classNames(styles.container, className)}>
        {children}
        <input
            type="checkbox"
            ref={register}
            name={name}
            required={required}
            checked={checked}
            onChange={handleChange}
        />
        <span className={styles.checkmark}/>
      </label>
  );
}
