import {storySummaryBase} from "@/lib/api/sanity/queries/storyBase";

const recentStoriesByCategory = `
* [_type == 'story']
[$categoryId in categories[]->._id]
[!(_id in $excludeIds + $excludeIds[]{'id':"drafts." + @}.id)]
| order(publishedAt desc)
${storySummaryBase}
[$start...$end]
`
export default recentStoriesByCategory
