import React from "react";
import styles from './Styles.module.scss';
import classNames from 'classnames';
import TextInput from "@/components/input/text/TextInput";

export default function EmailInput({
                                     className,
                                     required,
                                     name,
                                     register,
                                     placeholder,
                                     defaultValue,
                                     value,
                                     onChange,
                                     onValueChange,
                                     disabled,
                                   }) {

  return (
      <TextInput
          name={name}
          register={register}
          type={"email"}
          placeholder={placeholder || "Email"}
          defaultValue={defaultValue}
          required={required}
          value={value}
          onChange={onChange}
          onValueChange={onValueChange}
          className={classNames(styles.container, className)}
          disabled={disabled}
      />
  )
}
