import React from "react";
import styles from './Nav.module.scss';
import classNames from 'classnames/bind';
import BurgerButton from "@/components/button/burger/BurgerButton";
import Logo from "@/components/svg/Logo";
import Link from "next/link";
import {categoryToPath} from "@/lib/utils/categoryUtils";
import {useRouter} from "next/router";
import Button from "@/components/button/Button";
import {useContext} from "@/lib/utils/context";
import AccountIcon from "@/components/svg/AccountIcon";
import SearchButton from "@/components/search/button/SearchButton";

const cx = classNames.bind(styles);

export default function Nav(props) {

  const router = useRouter();

  const isMenuOpen = props.isMenuOpen;
  const onBurgerClick = props.onBurgerClick;

  const {config, categories, auth, setSearchFunction} = useContext();

  const onBurgerSearchClick = () => {
    onBurgerClick();
    onSearchClick();
  }

  const onSearchClick = () => {
    if (router.pathname !== '/search') {
      setSearchFunction(prevState => !prevState);
    }
  }

  const categoryToMenuItem = (category) => ({
    title: category.title,
    link: categoryToPath(category),
  });

  const menuItems = [
    ...categories.map(categoryToMenuItem),
  ];

  const MenuItem = ({href, title, isSelected, className}) => {
    return (
        <Link href={href.startsWith('/') ? props.link : `/${href}`}>
          <a className={cx(styles.menuItem, {isSelected}, className)}>
            {title}
          </a>
        </Link>
    )
  }

  return (
      <div className={classNames(styles.container, props.className)}>
        {!config.preRelease &&
        <>
          <BurgerButton className={classNames(styles.burgerButton)} onClick={onBurgerClick} isOpen={isMenuOpen}/>
          {isMenuOpen && ( <SearchButton className={styles.burgerSearch} handleClick={onBurgerSearchClick} /> )}
        </>
        }
        <Link href="/">
          <a><Logo className={styles.logo}/></a>
        </Link>
        {!config.preRelease &&
        <div className={styles.menu}>
          <div className={styles.menuItems}>
            {menuItems.map((item, i) => (
                <MenuItem key={i}
                          href={item.link}
                          title={item.title}
                          isSelected={router.asPath.substring(1).startsWith(item.link)}
                />
            ))}
          </div>
          {auth && <div className={styles.noWrap}>
            {auth.user ? <Link href={'/account'}>
                  <a className={styles.login}>
                    Account
                    <AccountIcon className={styles.icon}/>
                  </a>
                </Link>
                : <Link href={'/login'}>
                  <a className={styles.login}>
                    Log in
                  </a>
                </Link>}
            {config.allowMagazineSubscription &&
            <Link href="/subscribe">
              <a>
                <Button solid className={styles.button}>SUBSCRIBE</Button>
              </a>
            </Link>}
            <SearchButton handleClick={onSearchClick} className={styles.menuSearch}/>
          </div>}
        </div>
        }
      </div>
  )
}
