import React from "react";
import styles from './Styles.module.scss';
import classNames from 'classnames/bind';
import CloseButton from "@/components/button/close/CloseButton";
import {useRouter} from "next/router";

const cx = classNames.bind(styles);

export default function ErrorDialog({onClose}) {

  const router = useRouter();

  function handleBackClick() {
    router.back();
  }

  function handleCloseClick() {
    if (onClose) {
      onClose();
    }
  }

  return (
      <div className={styles.background}>
        <div className={styles.backgroundClickSurface} onClick={handleCloseClick}/>
        <div className={styles.dialog}>
          <CloseButton className={styles.closeButton} onClick={handleCloseClick}/>
          <div className={styles.content}>
            <h1>
              Uh oh, something went wrong
            </h1>
            <p>
              Sorry it looks like something went wrong at our end. If your problem persists, please get in touch with us
              at <a href={"mailto:hello@schoolroad.com"}>hello@schoolroad.com</a> and we will try to help you with your issue.
            </p>
            <p>
              <a onClick={handleBackClick}>GO BACK</a>
            </p>
          </div>
        </div>
      </div>
  )
}
