import {storySummaryBase} from "@/lib/api/sanity/queries/storyBase";

const recentStoriesBySite = `
* [_type == 'story']
[!(_id in $excludeIds + $excludeIds[]{'id':"drafts." + @}.id)]
| order(publishedAt desc)
${storySummaryBase}
[$site in categories[].site]
[$start...$end]
`
export default recentStoriesBySite
