import {storySummaryBase} from "@/lib/api/sanity/queries/storyBase";

const categoryById = `
* [_type == 'category' && _id == $categoryId]
{
  'id': _id,
  title,
  slug,
  'site': site -> slug.current,
  description,
  hero -> ${storySummaryBase},
}
[0]
`

export default categoryById
