import React, {useEffect, useState} from "react";
import Footer from "@/components/footer/Footer";
import Meta from "@/components/meta/Meta";
import styles from './Styles.module.scss';
import classNames from 'classnames/bind';
import waitForFonts from "@/lib/utils/waitForFonts";
import PreviewAlert from "@/components/preview/PreviewAlert";
import Menu from "@/components/nav/menu/Menu";
import Nav from "@/components/nav/Nav";
import {useContext} from "@/lib/utils/context";
import Loader from "@/components/loader/Loader";
import ConfirmDialog from "@/components/dialog/confirm/ConfirmDialog";
import ErrorDialog from "@/components/dialog/error/ErrorDialog";
import Banner from "@/components/banner/Banner";
import NewsletterPopUp from "@/components/popup/NewsletterPopUp";
import SearchWidget from "@/components/search/SearchWidget";

const cx = classNames.bind(styles);

export default function Layout({className, children, meta, loading}) {

  const {config, site, form, auth, dialog, setDialog, error, setError, searchFunction, setSearchFunction} = useContext();
  const {preview} = config;
  const {submitting} = form;
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    waitForFonts()
        .then(() => setFontsLoaded(true))
        .catch(console.log)
  });

  const onNavigate = () => {
    setMenuOpen(false);
  }

  const onBurgerClick = () => {
    setMenuOpen(prevValue => !prevValue);
    setSearchFunction(false);
  }

  return (
      <>
        <Meta data={meta}/>
        <div className={cx(styles.container, {fontsLoaded, preview, isMenuOpen})}>
          <div className={classNames(styles.content, className)}>
            {dialog && <ConfirmDialog
                onClose={() => setDialog(null)}
                {...dialog}
            />}
            {error && <ErrorDialog
                onClose={() => setError(null)}
                {...error}
            />}
            {site.newsletterPopup.enabled && <NewsletterPopUp /> }
            <Banner/>
            {!auth || loading
                ? <div className={styles.loaderContainer}>
                  <Loader className={styles.loader}/>
                </div>
                : children}
          </div>
          <Footer/>
          <PreviewAlert className={styles.previewAlert}/>
          <Menu onNavigate={onNavigate} className={styles.menu}/>
          <Nav className={styles.nav} onBurgerClick={onBurgerClick} isMenuOpen={isMenuOpen}/>
          {searchFunction && <SearchWidget />}
          <div className={cx({submitting})}/>
        </div>
      </>
  )
}
