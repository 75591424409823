import React from 'react';
import styles from './SearchBar.module.scss';
import { useContext } from '@/lib/utils/context';
import TextInput from '@/components/input/text/TextInput';
import CtaArrow from '@/components/svg/CtaArrow';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';

const cx = classNames.bind(styles);

export default function SearchBar({ noWidget, defaultValue }) {
  const { config, setSearchFunction } = useContext();
  const router = useRouter();

  const { register, handleSubmit, watch } = useForm();
  const formValues = watch();

  const onSubmit = () => {
    const { searchInput } = formValues;
    if (searchInput !== '') {
      router.push(`/search?query=${searchInput.replaceAll(' ', '+')}`);
      setSearchFunction(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className={cx(styles.searchBox,
          noWidget ? styles.noWidget : '',
          !config.allowMagazineSubscription ? styles.noSubscribeButton : ''
        )}
      >
        <div className={styles.inputContainer}>
          <TextInput
            className={styles.searchInput}
            placeholder="Search"
            autoFocus
            name="searchInput"
            register={register}
            defaultValue={defaultValue ? defaultValue : ''}
          />
          <div className={styles.arrowIconContainer} onClick={onSubmit}>
            <CtaArrow className={styles.arrowIcon} />
          </div>
        </div>
      </div>
    </form>
  );
}
