import { useContext } from '@/lib/utils/context';
import styles from './Styles.module.scss';
import React, { useEffect, useState } from 'react';
import CloseButton from '@/components/button/close/CloseButton';
import TextInput from '@/components/input/text/TextInput';
import EmailInput from '@/components/input/email/EmailInput';
import Button from '@/components/button/Button';
import CheckboxInput from '@/components/input/checkbox/CheckboxInput';
import { useForm } from 'react-hook-form';
import classNames from 'classnames/bind';
import getImageUrls from '@/lib/utils/getImageUrls';
import { createContact } from '@/lib/api/api';

const cx = classNames.bind(styles);

export default function NewsletterPopUp() {
  const { site, auth, authLoaded, popUps, setPopUps, popUpsLoaded } = useContext();

  const [showPopUp, setShowPopUp] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const { register, handleSubmit, watch } = useForm();
  const formValues = watch();

  // Wait for auth and popUps localstorage to load before making a decision about popup display.
  useEffect(() => {
    if (authLoaded && popUpsLoaded) {
      // If the user isn't logged in and hasn't been shown the pop-up before.
      if (!auth?.user && !popUps?.newsletterPopUp?.displayTimestamp) {
        setTimeout(() => {
          setShowPopUp(true);
        }, site.newsletterPopup.delay * 1000);
      }
    }
  }, [authLoaded, popUpsLoaded]);

  // Close success message 5 seconds after it's displayed.
  useEffect(() => {
    if (formSubmitted) {
      setTimeout(() => {
        handleClose();
      }, 5000);
    }
  }, [formSubmitted]);

  const handleClose = () => {
    setShowPopUp(false);
    setFormSubmitted(false);
    setPopUps((prevState) => ({
      ...prevState,
      newsletterPopUp: {
        displayTimestamp: new Date(),
      },
    }));
  };

  const onSubmit = async () => {
    try {
      setSubmitting(true);
      const response = await createContact(formValues);
      if (response) {
        setFormSubmitted(true);
      }
    } catch (error) {
      setError(true);
      handleClose();
    } finally {
      setSubmitting(false);
    }
  };

  if (!showPopUp) {
    return null;
  }
  return (
    <div className={styles.background}>
      <div className={styles.backgroundClickSurface} onClick={handleClose} />
      <div className={styles.dialog}>
        <div className={styles.imageWrapper}>
          <img
            src={getImageUrls(site.newsletterPopup.Image, { width: 360 }).url}
          />
        </div>
        <div
          className={cx(
            styles.contentWrapper,
            formSubmitted ? 'formSubmitted' : ''
          )}
        >
          <CloseButton className={styles.closeButton} onClick={handleClose} />
          {formSubmitted && (
            <>
              <h1>{site.newsletterPopup.SuccessHeading}</h1>
              <p>{site.newsletterPopup.SuccessText}</p>
            </>
          )}
          {!formSubmitted && (
            <>
              <h1>{site.newsletterPopup.Heading}</h1>
              <p>{site.newsletterPopup.Text}</p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextInput
                  className={styles.input}
                  required
                  placeholder="First Name"
                  name={'firstName'}
                  register={register}
                />
                <TextInput
                  className={styles.input}
                  required
                  placeholder="Last Name"
                  name={'lastName'}
                  register={register}
                />
                <EmailInput
                  className={styles.input}
                  required
                  register={register}
                  name={'email'}
                />
                <CheckboxInput className={styles.checkbox} required>
                  {`Yes, I wish to receive communications from ${site.name} magazine.`}
                </CheckboxInput>
                <Button
                  solid
                  darkBackground
                  submitting={submitting}
                  className={styles.button}
                >
                  sign up
                </Button>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
