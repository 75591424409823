import React from "react";

export default function IconInsta({ className }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
            className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.78261 5C8.81521 5 7.86952 5.29325 7.06515 5.84265C6.26078 6.39206 5.63385 7.17295 5.26364 8.08658C4.89343 9.00021 4.79656 10.0055 4.9853 10.9755C5.17403 11.9454 5.63988 12.8363 6.32394 13.5355C7.008 14.2348 7.87955 14.711 8.82837 14.9039C9.77719 15.0969 10.7607 14.9978 11.6544 14.6194C12.5482 14.241 13.3121 13.6001 13.8496 12.7779C14.387 11.9556 14.6739 10.9889 14.6739 10C14.6739 8.67392 14.1586 7.40215 13.2413 6.46447C12.324 5.52678 11.0799 5 9.78261 5ZM9.78261 13.3333C9.13768 13.3333 8.50722 13.1378 7.97097 12.7716C7.43473 12.4053 7.01677 11.8847 6.76996 11.2756C6.52316 10.6665 6.45858 9.9963 6.5844 9.3497C6.71022 8.7031 7.02079 8.10915 7.47683 7.64298C7.93287 7.1768 8.5139 6.85933 9.14645 6.73072C9.779 6.6021 10.4346 6.66811 11.0305 6.9204C11.6263 7.17269 12.1356 7.59994 12.4939 8.1481C12.8522 8.69626 13.0435 9.34073 13.0435 10C13.0435 10.8841 12.6999 11.7319 12.0884 12.357C11.4769 12.9821 10.6475 13.3333 9.78261 13.3333Z"
                fill="currentColor" />
            <path d="M16.3043 4.58333C16.3043 5.27369 15.7568 5.83333 15.0815 5.83333C14.4062 5.83333 13.8587 5.27369 13.8587 4.58333C13.8587 3.89298 14.4062 3.33333 15.0815 3.33333C15.7568 3.33333 16.3043 3.89298 16.3043 4.58333Z"
                fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.3043 0H3.26087C2.39603 0 1.56662 0.35119 0.955087 0.976311C0.343555 1.60143 0 2.44928 0 3.33333V16.6667C0 17.5507 0.343555 18.3986 0.955087 19.0237C1.56662 19.6488 2.39603 20 3.26087 20H16.3043C17.1692 20 17.9986 19.6488 18.6101 19.0237C19.2217 18.3986 19.5652 17.5507 19.5652 16.6667V3.33333C19.5652 2.44928 19.2217 1.60143 18.6101 0.976311C17.9986 0.35119 17.1692 0 16.3043 0ZM17.9348 16.6667C17.9348 17.1087 17.763 17.5326 17.4572 17.8452C17.1515 18.1577 16.7368 18.3333 16.3043 18.3333H3.26087C2.82845 18.3333 2.41374 18.1577 2.10798 17.8452C1.80221 17.5326 1.63043 17.1087 1.63043 16.6667V3.33333C1.63043 2.89131 1.80221 2.46738 2.10798 2.15482C2.41374 1.84226 2.82845 1.66667 3.26087 1.66667H16.3043C16.7368 1.66667 17.1515 1.84226 17.4572 2.15482C17.763 2.46738 17.9348 2.89131 17.9348 3.33333V16.6667Z"
                fill="currentColor" />
        </svg>
    )
}
